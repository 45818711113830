import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import getCartLineItems from './api/getCartLineItems';
import Loader from './Loader';
import Axios from 'axios';
import DeleteItemIcon from './DeleteItemIcon';

const CartContainer = styled.div`
    background-color: white;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 80%;
    height: 100%;
`;

const CartHeader = styled.h2`
    font-size: 1.25rem;
    font-weight: 500;
`;

const ShippingPromo = styled.p`
    background-color: #f7f7f7;

    em {
        color: #f36f22;
        font-style: normal;
    }
`;

const LineItems = ({ items, number, removeLineItem, token }) => {
    const [itemToDelete, setItemToDelete] = useState('');

    const handleClick = (id) => {
        setItemToDelete(id);
        Axios.request({
            url: `/api/v1/orders/${number}/line_items/${id}?order_token=${token}`,
            method: 'DELETE',
        })
            .then((res) => res.data)
            .then(
                (data) => {
                    setItemToDelete('');
                    removeLineItem(id);
                    Spree.fetchCart();
                },
                (err) => {}
            );
    };

    return (
        <ul className="unstyled-list p-0">
            {items.map(({ id, variant, quantity }, i) => {
                const leadImage =
                    variant.images.length === 0 ? null : variant.images[0];
                return (
                    <li
                        className="py-3 d-flex align-items-center border-bottom"
                        key={i}>
                        {leadImage && (
                            <div
                                className="mr-1"
                                style={{
                                    width: '70px',
                                    height: '70px',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url('${leadImage.small_url}')`,
                                }}
                            />
                        )}
                        <div
                            className="d-flex flex-column px-2"
                            style={{ opacity: id === itemToDelete ? 0.5 : 1 }}>
                            <p className="mb-0">{variant.name}</p>
                            <p className="mb-2 small d-flex">
                                {variant.option_values.map((opt) => (
                                    <span
                                        key={opt.id}
                                        className="mr-1 text-dark">
                                        <strong>{opt.option_type_name}:</strong>{' '}
                                        {opt.name}
                                    </span>
                                ))}
                            </p>
                            <p className="mb-1">{variant.display_price}</p>
                            <p className="mb-0">Qty: {quantity}</p>
                        </div>
                        <button
                            className="btn btn-link text-dark ml-auto"
                            onClick={handleClick.bind(null, id)}>
                            <DeleteItemIcon />
                        </button>
                    </li>
                );
            })}
        </ul>
    );
};

const Cart = ({ number, token }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [lineItems, setLineItems] = useState([]);

    const removeLineItem = (id) => {
        setLineItems(lineItems.filter((l) => l.id !== id));
    };

    useEffect(() => {
        setIsLoading(true);
        if (number && token) {
            getCartLineItems({ number, token }).then(
                (data) => {
                    setLineItems(data.line_items);
                    setIsLoading(false);
                },
                (err) => {
                    setLineItems([]);
                    setIsLoading(false);
                }
            );
        } else {
            setIsLoading(false);
            setLineItems([]);
        }
    }, []);

    const totalQuantity = lineItems.reduce((acc, cur) => acc + cur.quantity, 0);

    return (
        <CartContainer
            className="py-3"
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <div className="px-4">
                <CartHeader className="text-uppercase">
                    Shopping Bag{' '}
                    {isLoading
                        ? ''
                        : `(${totalQuantity} Item${
                              totalQuantity === 1 ? '' : 's'
                          })`}
                </CartHeader>
            </div>
            <div
                className="px-4 py-4 border-top border-primary"
                style={{ overflowY: 'scroll' }}>
                {isLoading ? (
                    <Loader />
                ) : lineItems.length > 0 ? (
                    <LineItems
                        items={lineItems}
                        removeLineItem={removeLineItem}
                        number={number}
                        token={token}
                    />
                ) : (
                    <p>No items in cart</p>
                )}
            </div>
            <div className="px-4  pt-4 d-flex align-items-center mt-auto border-top border-primary">
                <a href="/cart" className="btn btn-outline-dark mr-2 w-100">
                    Visit Cart
                </a>
                <a href="/checkout" className="btn btn-primary w-100">
                    Checkout
                </a>
            </div>
        </CartContainer>
    );
};

export default Cart;
