import React from 'react';

export default ({ size = 17 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        viewBox="0 0 16.664 18.736"
        width={size * 1.11}
        className="spree-icon">
        <g fill="currentColor">
            <path
                d="m43.043 2.7h-3.9v-.639a2.063 2.063 0 0 0 -2.059-2.061h-3.705a2.063 2.063 0 0 0 -2.061 2.061v.639h-3.9a.52.52 0 1 0 0 1.04h.94v12.215a2.785 2.785 0 0 0 2.781 2.781h8.181a2.785 2.785 0 0 0 2.78-2.781v-12.219h.94a.52.52 0 1 0 0-1.04zm-10.685-.639a1.023 1.023 0 0 1 1.021-1.021h3.706a1.023 1.023 0 0 1 1.021 1.021v.639h-5.748zm8.705 13.894a1.744 1.744 0 0 1 -1.741 1.745h-8.181a1.744 1.744 0 0 1 -1.741-1.745v-12.219h11.667v12.219z"
                transform="translate(-26.9)"></path>
            <path
                d="m230.22 155.631a.518.518 0 0 0 .52-.52v-9.191a.52.52 0 0 0 -1.04 0v9.187a.521.521 0 0 0 .52.524z"
                transform="translate(-221.888 -139.799)"></path>
            <path
                d="m142.12 169.287a.518.518 0 0 0 .52-.52v-8.047a.52.52 0 0 0 -1.04 0v8.047a.52.52 0 0 0 .52.52z"
                transform="translate(-137.182 -154.029)"></path>
            <path
                d="m318.32 169.287a.518.518 0 0 0 .52-.52v-8.047a.52.52 0 0 0 -1.04 0v8.047a.518.518 0 0 0 .52.52z"
                transform="translate(-306.595 -154.029)"></path>
        </g>
    </svg>
);
