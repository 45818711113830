import React, { useEffect, useState, Fragment } from 'react';
import { render } from 'react-dom';
import styled from '@emotion/styled';
import Cart from './Cart';
import Cookies from 'js-cookie';

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(170, 170, 170, 0.8);
    overflow: hidden;
    z-index: 20;
`;

const App = () => {
    const [showCart, setShowCart] = useState(false);
    const cartBtn = document.getElementById('link-to-cart');
    const [orderNumber, setOrderNumber] = useState('');
    const [orderToken, setOrderToken] = useState('');

    useEffect(() => {
        const initialOrderNumber =
            Cookies.get('orderNumber') || Spree.current_order_id;
        const initialOrderToken =
            Cookies.get('orderToken') || Spree.current_order_token;

        setOrderNumber(initialOrderNumber);
        setOrderToken(initialOrderToken);
    }, []);

    useEffect(() => {
        const toggleCart = (e) => {
            e.preventDefault();
            setShowCart(!showCart);
        };

        if (showCart) {
            document.body.style = 'overflow: hidden';
        } else {
            document.body.style = 'overflow: visible';
        }

        cartBtn && cartBtn.addEventListener('click', toggleCart);

        return () =>
            cartBtn && cartBtn.removeEventListener('click', toggleCart);
    }, [showCart]);

    const handleSubmit = function (event) {
        var variantId;
        var quantity;
        var $cartForm = $(event.currentTarget);
        var $addToCart = $cartForm.find(ADD_TO_CART_SELECTOR);

        event.preventDefault();
        $addToCart.prop('disabled', true);
        variantId = $cartForm.find(VARIANT_ID_SELECTOR).val();
        quantity = parseInt($cartForm.find('[name="quantity"]').val());
        Spree.ensureCart(function () {
            SpreeAPI.Storefront.addToCart(
                variantId,
                quantity,
                {},
                function (res) {
                    /* Custom code */
                    const { number, token } = res.attributes;
                    Cookies.set('orderNumber', number);
                    Cookies.set('orderToken', token);
                    setOrderNumber(number);
                    setOrderToken(token);
                    /* end custom code */
                    $addToCart.prop('disabled', false);
                    Spree.fetchCart();
                    Spree.showProductAddedModal(
                        JSON.parse($cartForm.attr('data-product-summary')),
                        Spree.variantById($cartForm, variantId)
                    );
                },
                function (error) {
                    if (typeof error === 'string' && error !== '') {
                        document.querySelector(
                            '#no-product-available .no-product-available-text'
                        ).innerText = error;
                    }
                    document.getElementById('overlay').classList.add('shown');
                    document
                        .getElementById('no-product-available')
                        .classList.add('shown');
                    window.scrollTo(0, 0);
                    $addToCart.prop('disabled', false);
                } // failure callback for 422 and 50x errors
            );
        });
    };

    useEffect(() => {
        let $productDetails;
        Spree.ready(function ($) {
            Spree.variantById = function ($cartForm, variantId) {
                var cartFormVariants = JSON.parse(
                    $cartForm.attr('data-variants')
                );
                return (
                    cartFormVariants.find(function (variant) {
                        return variant.id.toString() === variantId;
                    }) || null
                );
            };

            $productDetails = $('#product-details');

            $productDetails.off('submit');
            $productDetails.on('submit', '.js-add-to-cart-form', handleSubmit);

            $('.js-add-to-cart-form').each(function (
                _cartFormIndex,
                cartFormElement
            ) {
                var $cartForm = $(cartFormElement);
                CartForm($, $cartForm);
            });
        });

        return () => $productDetails.off('submit');
    }, []);

    return (
        <Fragment>
            {showCart && (
                <Container onClick={() => setShowCart(false)}>
                    <Cart
                        number={orderNumber}
                        token={orderToken}
                        show={showCart}
                    />
                </Container>
            )}
        </Fragment>
    );
};

document.addEventListener('turbolinks:load', function () {
    const dom = document.getElementById('app');
    render(<App />, dom);
});
